html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.datatable {
  flex: 1; /* or height: 100% */
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8rem;
}

.dragNDropContainer {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  width: 300px;
  padding: 30px;

  .box {
    text-align: center;
    color: white;
    font-family: sans-serif;
    font-size: 36px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .box-1 {
    height: 60px;
  }

  .box-2 {
    height: 10px;
  }
}
